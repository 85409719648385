module.exports = {
  NOTICE_TYPE: {
    INFO: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4
  },
  ROLES: {
    SUPER_ADMINISTRATOR: 1,
    CUSTOMER: 2,
    RECEIVER: 3,
    COLLABORATOR: 4,
    GENERAL_MANAGER: 5,
    MANAGER: 6,
    ACCOUNTANT: 7,
    PACKAGER: 8,
    SUPERVISOR: 9,
    SALE: 10
  },
  ORDER_STATUS: {
    NEW_WAIT_CONF: 0,
    CONF_WAIT_PARCEL: 1,
    IN_WAREHOUSE_WAIT_PACKAGE: 2,
    PACKAGED_WAIT_TRANSIT: 3,
    TRANSITED_WAIT_COMPLETE: 4,
    COMPLETED: 5,
    RETURN: 6,
    RESERVE: 7,
    CANCELED: 8,
    UPDATE_WAIT_CONF: 9,
    LOSS: 10,
    REIMBURSEMENT: 11,
  },
  TRACKING_NUMBER_BUY_STATUS: {
    DRAFT: 0,
    WAIT_BUY: 1,
    BUY_SUCCESS: 2,
    BUY_FAIL: 3,
    READY_TO_BUY: 4,
    BUYING: 5,
    BUYING_IN_PROGRESS: 6,
  },
  TRACKING_NUMBER_REGISTER_STATUS: {
    UNREGISTERED: 0,
    REGISTERED: 1,
    REGISTER_FAIL: 2,
  },
  BIG_CONTAINER_STATUS: {
    NEW: 0,
    PACKAGED: 1,
    IN_TRANSIT: 2,
    COMPLETED: 3,
    RETURN: 4,
    CANCELED: 5,
  },
  CUSTOMER_RANK: {
    STANDARD: 1,
    VIP_1: 2,
    VIP_2: 3,
  },
  SERVICE_TYPE: {
    SHIP_EXPRESS: 1,
    EPACKET_US: 2,
    EPACKET_AU: 3,
    EPACKET_WW: 4,
    DHL_ECOM_US: 5,
    DHL_ECOM_WW: 6,
    ECARGO_US: 7,
    BPOST: 8,
    SINGPOST: 9,
    LASTMILE_TRACKING: 10,
  },
  PAYMENT_TYPE: {
    DEPOSIT: 1,
    WITHDRAW: 2,
    PAY_ORDER: 3,
    OFFSET_BALANCE_ADDITION: 4,
    OFFSET_BALANCE_SUBTRACTION: 5,
    DISCOUNT: 6
  },
  PAYMENT_STATUS: {
    WAIT_PAY: 1,
    PAID: 2,
    REFUND: 3
  },
  WAREHOUSE_TYPE: {
    PUBLIC: 1,
    INTERNAL: 2
  },
  COUNTRY_US: 226,
  STATE_US: [
    {name: 'Armed Forces Americas', abbreviation: 'AA'},
    {name: 'Armed Forces Africa', abbreviation: 'AE'},
    {name: 'Alaska', abbreviation: 'AK'},
    {name: 'Alabama', abbreviation: 'AL'},
    {name: 'Armed Forces Pacific', abbreviation: 'AP'},
    {name: 'Arkansas', abbreviation: 'AR'},
    {name: 'Arizona', abbreviation: 'AZ'},
    {name: 'California', abbreviation: 'CA'},
    {name: 'Colorado', abbreviation: 'CO'},
    {name: 'Connecticut', abbreviation: 'CT'},
    {name: 'District Of Columbia', abbreviation: 'DC'},
    {name: 'Delaware', abbreviation: 'DE'},
    {name: 'Florida', abbreviation: 'FL'},
    {name: 'Georgia', abbreviation: 'GA'},
    {name: 'Guam', abbreviation: 'GU'},
    {name: 'Hawaii', abbreviation: 'HI'},
    {name: 'Iowa', abbreviation: 'IA'},
    {name: 'Idaho', abbreviation: 'ID'},
    {name: 'Illinois', abbreviation: 'IL'},
    {name: 'Indiana', abbreviation: 'IN'},
    {name: 'Kansas', abbreviation: 'KS'},
    {name: 'Kentucky', abbreviation: 'KY'},
    {name: 'Louisiana', abbreviation: 'LA'},
    {name: 'Massachusetts', abbreviation: 'MA'},
    {name: 'Maryland', abbreviation: 'MD'},
    {name: 'Maine', abbreviation: 'ME'},
    {name: 'Michigan', abbreviation: 'MI'},
    {name: 'Minnesota', abbreviation: 'MN'},
    {name: 'Missouri', abbreviation: 'MO'},
    {name: 'Mississippi', abbreviation: 'MS'},
    {name: 'Montana', abbreviation: 'MT'},
    {name: 'North Carolina', abbreviation: 'NC'},
    {name: 'North Dakota', abbreviation: 'ND'},
    {name: 'Nebraska', abbreviation: 'NE'},
    {name: 'New Hampshire', abbreviation: 'NH'},
    {name: 'New Jersey', abbreviation: 'NJ'},
    {name: 'New Mexico', abbreviation: 'NM'},
    {name: 'Nevada', abbreviation: 'NV'},
    {name: 'New York', abbreviation: 'NY'},
    {name: 'Ohio', abbreviation: 'OH'},
    {name: 'Oklahoma', abbreviation: 'OK'},
    {name: 'Oregon', abbreviation: 'OR'},
    {name: 'Pennsylvania', abbreviation: 'PA'},
    {name: 'Puerto Rico', abbreviation: 'PR'},
    {name: 'Rhode Island', abbreviation: 'RI'},
    {name: 'South Carolina', abbreviation: 'SC'},
    {name: 'South Dakota', abbreviation: 'SD'},
    {name: 'Tennessee', abbreviation: 'TN'},
    {name: 'Texas', abbreviation: 'TX'},
    {name: 'Utah', abbreviation: 'UT'},
    {name: 'Virginia', abbreviation: 'VA'},
    {name: 'Virgin Island', abbreviation: 'VI'},
    {name: 'Vermont', abbreviation: 'VT'},
    {name: 'Washington', abbreviation: 'WA'},
    {name: 'Wisconsin', abbreviation: 'WI'},
    {name: 'West Virginia', abbreviation: 'WV'},
    {name: 'Wyoming', abbreviation: 'WY'},
    {name: 'AA', abbreviation: 'aa'},
    {name: 'AE', abbreviation: 'ae'},
    {name: 'AK', abbreviation: 'ak'},
    {name: 'AL', abbreviation: 'al'},
    {name: 'AP', abbreviation: 'ap'},
    {name: 'AR', abbreviation: 'ar'},
    {name: 'AZ', abbreviation: 'az'},
    {name: 'CA', abbreviation: 'ca'},
    {name: 'CO', abbreviation: 'co'},
    {name: 'CT', abbreviation: 'ct'},
    {name: 'DC', abbreviation: 'dc'},
    {name: 'DE', abbreviation: 'de'},
    {name: 'FL', abbreviation: 'fl'},
    {name: 'GA', abbreviation: 'ga'},
    {name: 'GU', abbreviation: 'gu'},
    {name: 'HI', abbreviation: 'hi'},
    {name: 'IA', abbreviation: 'ia'},
    {name: 'ID', abbreviation: 'id'},
    {name: 'IL', abbreviation: 'il'},
    {name: 'IN', abbreviation: 'in'},
    {name: 'KS', abbreviation: 'ks'},
    {name: 'KY', abbreviation: 'ky'},
    {name: 'LA', abbreviation: 'la'},
    {name: 'MA', abbreviation: 'ma'},
    {name: 'MD', abbreviation: 'md'},
    {name: 'ME', abbreviation: 'me'},
    {name: 'MI', abbreviation: 'mi'},
    {name: 'MN', abbreviation: 'mn'},
    {name: 'MO', abbreviation: 'mo'},
    {name: 'MS', abbreviation: 'ms'},
    {name: 'MT', abbreviation: 'mt'},
    {name: 'NC', abbreviation: 'nc'},
    {name: 'ND', abbreviation: 'nd'},
    {name: 'NE', abbreviation: 'ne'},
    {name: 'NH', abbreviation: 'nh'},
    {name: 'NJ', abbreviation: 'nj'},
    {name: 'NM', abbreviation: 'nm'},
    {name: 'NV', abbreviation: 'nv'},
    {name: 'NY', abbreviation: 'ny'},
    {name: 'OH', abbreviation: 'oh'},
    {name: 'OK', abbreviation: 'ok'},
    {name: 'OR', abbreviation: 'or'},
    {name: 'PA', abbreviation: 'pa'},
    {name: 'PR', abbreviation: 'pr'},
    {name: 'RI', abbreviation: 'ri'},
    {name: 'SC', abbreviation: 'sc'},
    {name: 'SD', abbreviation: 'sd'},
    {name: 'TN', abbreviation: 'tn'},
    {name: 'TX', abbreviation: 'tx'},
    {name: 'UT', abbreviation: 'ut'},
    {name: 'VA', abbreviation: 'va'},
    {name: 'VI', abbreviation: 'vi'},
    {name: 'VT', abbreviation: 'vt'},
    {name: 'WA', abbreviation: 'wa'},
    {name: 'WI', abbreviation: 'wi'},
    {name: 'WV', abbreviation: 'wv'},
    {name: 'WY', abbreviation: 'wy'}
  ]
}
